import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import { BeneficConfig } from "./interfaces";
import { BenefitCategory, BenefitSubcategory, Partner } from "./interfacess";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import ProdutoImage from "./ProdutoImage";
import AuthContext from "../../../../common/components/hooks/Auth";
import BenefitBannerUpload from "../NewBenefitsBanner/BenefitBannerUpload";
import axios, { AxiosResponse } from "axios";
import { benefits } from "../Benefit/types/benefits";


interface Errors {
  [field: string]: string;
}

export interface Evento {
  id: string;
  nome: string;
  descricao?: string;
  companyid: string;
  horainicio: string;  // Formato TIME (HH:mm:ss)
  horafim: string;     // Formato TIME (HH:mm:ss)
  datainicio: string;  // Formato DATE (YYYY-MM-DD)
  datafim?: string;    // Formato DATE (YYYY-MM-DD), pode ser opcional
  foto?: string;       // URL ou caminho do arquivo da foto
  capacidademax: number;
  banner?: string;     // URL ou caminho do arquivo do banner
  modalidade: string;
  rua?: string;
  cidade?: string;
  cep?: string;
  numero?: string;
  bairro?: string;
  uf?: string;
  link?: string;
  publicoalvo?: string;
  categoriaevento?: string;
  valor?: string;
}

const initialState: Evento = {
  id: "",
  nome: "",
  descricao: "",
  companyid: "",
  horainicio: "",
  horafim: "",
  datainicio: "",
  datafim: "",
  capacidademax: 0,
  modalidade: "",
  rua: "",
  cidade: "",
  cep: "",
  numero: "",
  bairro: "",
  uf: "",
  link: "",
  publicoalvo: "",
  categoriaevento: "",
  valor: ""
};
interface EventoResponse {
  data: Evento
}



const NewEvento: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedCompany, ...context } = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const [benefit, setBenefit] = useState(initialState);
  const [benefitLogo, setBenefitLogo] = useState<File>(null);
  const [banner, setBanner] = useState<File>(null);
  const [docs, setDocs] = useState<File[]>(null);
  const [pago, setPago] = useState(false);
  const [modalidade, setModalidade] = useState("")
  const handleChangeBenefitData = (obj: Object): void => {
    setBenefit(prev => ({
      ...prev,
      ...obj
    }));
  }

  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPago(event.target.checked);
  };


  const validateData = () => {
    let hasError = false;

    setErrorMessage({});

    return hasError;
  }

  const postBenefit = async () => {
    if (validateData()) return;


    // Adiciona arquivos, se existirem
    try {
      // Dados de texto
      const benefitData = {
        nome: benefit.nome,
        descricao: benefit.descricao || "",
        companyid: selectedCompany.id,
        horainicio: benefit.horainicio,
        horafim: benefit.horafim,
        datainicio: benefit.datainicio,
        datafim: benefit.datafim || "",
        capacidademax: (benefit.capacidademax),
        modalidade: benefit.modalidade,
        rua: benefit.rua || "",
        cidade: benefit.cidade || "",
        cep: benefit.cep || "",
        numero: benefit.numero || "",
        bairro: benefit.bairro || "",
        uf: benefit.uf || "",
        link: benefit.link || "",
        publicoalvo: benefit.publicoalvo || "",
        categoriaevento: benefit.categoriaevento || "",
        valor: benefit.valor || "",
        pago: pago ? true : false
      };

      // Envio dos dados de texto
      const response: AxiosResponse<EventoResponse> = await api.post("/sign_up/evento", benefitData);
      console.log(response)
      console.log(response.data.data.id)
      const eventoId = response;

      resetForm();
      alert("Produto criado com sucesso!");

      if (benefitLogo) {
        // Cria um FormData para o upload da foto
        const formData = new FormData();
        formData.append('foto', benefitLogo);

        // Envio da foto
        await api.post(`/sign_up/eventos/${response.data.data.id}/foto`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        alert("Foto enviada com sucesso!");
      }else{
        alert("É necessario colocar uma foto")
      }

      if (banner) {
        // Cria um FormData para o upload da foto
        const formData = new FormData();
        formData.append('foto', banner);

        // Envio da foto
        await api.post(`/sign_up/eventos/${response.data.data.id}/banner`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        alert("Foto enviada com sucesso!");
      }else{
        alert("É necessario colocar um banner")
      }

      history.push("/evento");


    }


    catch (e) {
      console.log(e);
    }
  }

  const resetForm = (): void => {
    setBenefit(initialState);
    setBenefitLogo(null);
  }
  useEffect(() => {
    setLoading(false); // Set loading to false once component is fully loaded
  }, []);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>

        <GridWrapper>
          <WrapperBack>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "2%", marginBottom: "5%", paddingRight: "2%", paddingLeft: "2%" }}>
              <div>
                <PageTitle
                  title="Novo Evento"
                  subtitle="Aqui você pode criar um novo entendo."
                />
              </div>
              <Stack direction="row" spacing={1}>
                <Chip label="Eventos"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponibilizar/produto`)}
                />
                <Chip label="Produtos Disponiveis"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponivel/lojinha`)}
                />

              </Stack>
            </div>

            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item xs={12} md={4} style={{ marginTop: "-3%" }}>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
                  <ProdutoImage onChangeImage={setBenefitLogo} src="" />
                </div>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="produto"
                      name="produto"
                      fullWidth
                      autoFocus
                      label="Nome"
                      autoComplete="Nome"
                      onChange={(e) =>
                        handleChangeBenefitData({ nome: e.target.value })
                      }
                      value={benefit.nome}
                      size="small"
                    />
                    {formErrors.produto && (
                      <S.ErrorMessage>
                        {formErrors.produto}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      label="Publico alvo"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ publicoalvo: e.target.value })
                      }
                      value={benefit.publicoalvo}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      label="Capacidade do evento"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ capacidademax: e.target.value })
                      }
                      value={benefit.capacidademax}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="categoria"
                      fullWidth
                      select
                      name="categoria"
                      label="Categoria"
                      autoComplete="pagamento"
                      onChange={(e) =>
                        handleChangeBenefitData({ categoriaevento: e.target.value })
                      }
                      value={benefit.categoriaevento}
                      size="small"
                    >
                      <MenuItem value="webinar">Webinar</MenuItem>
                      <MenuItem value="treinamento">Treinamento</MenuItem>
                      <MenuItem value="conferencia">Conferência</MenuItem>
                      <MenuItem value="seminario">Seminário</MenuItem>
                      <MenuItem value="forum">Fórum</MenuItem>
                      <MenuItem value="mesa_redonda">Mesa redonda</MenuItem>
                      <MenuItem value="simposio">Simpósio</MenuItem>
                      <MenuItem value="palestra">Palestra</MenuItem>
                      <MenuItem value="feira">Feira</MenuItem>
                      <MenuItem value="exposicao">Exposição</MenuItem>
                      <MenuItem value="lancamento_de_produto">Lançamento de produto</MenuItem>
                      <MenuItem value="encontro_de_networking">Encontro de networking</MenuItem>
                      <MenuItem value="hackathon">Hackathon</MenuItem>
                      <MenuItem value="retiro">Retiro</MenuItem>
                      <MenuItem value="evento_beneficente">Evento beneficente</MenuItem>
                      <MenuItem value="festival">Festival</MenuItem>
                      <MenuItem value="sessao_de_brainstorming">Sessão de brainstorming</MenuItem>
                      <MenuItem value="painel_de_discussao">Painel de discussão</MenuItem>
                      <MenuItem value="cerimonia_de_premiacao">Cerimônia de premiação</MenuItem>
                      <MenuItem value="audiencia_publica">Audiência pública</MenuItem>
                      <MenuItem value="ensaio_aberto">Ensaio aberto</MenuItem>
                      <MenuItem value="workshop_pratico">Workshop prático</MenuItem>

                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="modalidade"
                      fullWidth
                      select
                      name="modalidade"
                      label="Modalidade"
                      autoComplete="Modalidade"
                      onChange={(e) =>
                        handleChangeBenefitData({ modalidade: e.target.value })
                      }
                      value={benefit.modalidade}
                      size="small"
                    >
                      <MenuItem value="presencial">Presencial</MenuItem>
                      <MenuItem value="ead">EAD</MenuItem>
                      <MenuItem value="hibrido">Hibrido</MenuItem>
                    </TextField>
                  </Grid>
                  {benefit.modalidade && (
                    <>
                      {['ead', 'hibrido'].includes(benefit.modalidade) && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="outlined"
                            id="link"
                            fullWidth
                            label="Link"
                            value={benefit.link}
                            onChange={(e) => handleChangeBenefitData({ link: e.target.value })}
                            size="small"
                          />
                        </Grid>
                      )}
                      {['presencial', 'hibrido'].includes(benefit.modalidade) && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="endereco"
                              fullWidth
                              label="Endereço"
                              value={benefit.rua}
                              onChange={(e) => handleChangeBenefitData({ endereco: e.target.value })}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="cidade"
                              fullWidth
                              label="Cidade"
                              value={benefit.cidade}
                              onChange={(e) => handleChangeBenefitData({ cidade: e.target.value })}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="cep"
                              fullWidth
                              label="CEP"
                              value={benefit.cep}
                              onChange={(e) => handleChangeBenefitData({ cep: e.target.value })}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="numero"
                              fullWidth
                              label="Número"
                              value={benefit.numero}
                              onChange={(e) => handleChangeBenefitData({ numero: e.target.value })}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="bairro"
                              fullWidth
                              label="Bairro"
                              value={benefit.bairro}
                              onChange={(e) => handleChangeBenefitData({ bairro: e.target.value })}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="uf"
                              fullWidth
                              label="UF"
                              value={benefit.uf}
                              onChange={(e) => handleChangeBenefitData({ uf: e.target.value })}
                              size="small"
                            />
                          </Grid>
                        </>

                      )}
                    </>
                  )}
                  <Grid container xs={12} style={{ padding: "1rem" }}>
                    <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                      <TextField
                        id="modalidade"
                        type="checkbox"
                        name="modalidade"
                        onChange={handleCheckboxChange}
                        value={pago}
                      >
                      </TextField>
                      <label>Pago?</label>
                    </Grid>

                    {pago && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="valor"
                          variant="outlined"
                          label="Valor por ingresso"
                          type="text"
                          value={benefit.valor}
                          onChange={(e) => handleChangeBenefitData({ valor: e.target.value })}
                          fullWidth
                          size="small"

                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Data de inicio</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      type="date"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ datainicio: e.target.value })
                      }
                      value={benefit.datainicio}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Horario de inicio</label>

                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      type="time"
                      name="estoque"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ horainicio: e.target.value })
                      }
                      value={benefit.horainicio}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Data de encerramento</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      type="date"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ datafim: e.target.value })
                      }
                      value={benefit.datafim}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Hora de finalização</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      type="time"
                      name="estoque"
                      autoComplete="estoque"
                      onChange={(e) =>
                        handleChangeBenefitData({ horafim: e.target.value })
                      }
                      value={benefit.horafim}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      id="shortdescription"
                      fullWidth
                      name="shortdescription"
                      multiline
                      rows={4}
                      label="Descrição"
                      autoComplete="municipio"
                      onChange={(e) =>
                        handleChangeBenefitData({ descricao: e.target.value })
                      }
                      value={benefit.descricao}
                      size="small"
                    />
                    {formErrors.shortdescription && (
                      <S.ErrorMessage>
                        {formErrors.shortdescription}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                </Grid>
                <div style={{ marginTop: "15px" }}>
                  <BenefitBannerUpload
                    onChangeImage={setBanner}
                    src={null}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ display: "flex", padding: "1.5rem" }}>
              <Chip label="Adicionar Evento"
                style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  fontSize: "12px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                }}
                onClick={postBenefit}
              />
            </div>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default NewEvento;
