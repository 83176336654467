import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography"
import { useHistory, useParams } from "react-router-dom";
import { GridWrapper, Images, SectionTitle, SectionWrapper, Wrapper, WrapperBack, } from "./styles";
import api from "../../../../services/api";
import { Card as BenefitCard } from "../../../../common/components/Card";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Rating from '@mui/material/Rating';
import { Avatar, Button, Card, Chip, FormControlLabel, Paper, Switch, styled } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import PageSubTitle from "../../../../common/components/PagSubTitle";
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface Pacotes {
  id: string,
  name: string;
  qtdempresas: number;
  qtdusuarios: number;
  valor: number;
  descricao: string;
}
interface PacksCompanyConfig {
  id: string,
  packId: string,
  companyId: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
}

function Pacotes() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [pacotes, setPacotes] = useState<Pacotes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFullDescription, setShowFullDescription] = useState<{ [key: string]: boolean }>({});
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [buyPacks, setBuyPacks] = useState<PacksCompanyConfig[]>([]);
  const theme = useContext(ThemeContext);

  const getPacks = async () => {
    try {
      const { data } = await api.get("/pacotes");
      setPacotes(data);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const handleBuyPack = async (packId: string) => {
    setLoading(true); // Inicia o loading
    try {
      const response = await api.post(`/pacote/add-to-company`, {
        packId,
        companyId: selectedCompany.id,
        companyEmail: context.companyAdmin.email, // Inclua o email da empresa
        companyName: selectedCompany.name, // Inclua o nome da empresa
      });
  
      const sessionUrl = response.data; // Supondo que a URL da sessão é retornada aqui
  
      // Redireciona o usuário para a sessão de checkout do Stripe
      window.location.href = sessionUrl;
  
      // Opcional: Informar que o pacote foi adicionado à empresa
      alert("Redirecionando para o pagamento...");
  
      setBuyPacks([]);
      getPacks();
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os produtos da empresa.");
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  const DeletPacote = async (id: string) => {
    try {
      const { data } = await api.delete(`/delete/pacote/${id}`,);
      setPacotes(data.data.data);
    } catch (e) {
      getPacks();
      alert("Plano Deletado com sucesso!");


    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPacks();
  }, []);

  const getStarRating = (qtdusuarios: number) => {
    if (qtdusuarios <= 25) return 1;
    if (qtdusuarios <= 50) return 2;
    if (qtdusuarios <= 75) return 3;
    if (qtdusuarios <= 100) return 4;
    return 5;
  };

  const toggleDescription = (id: string) => {
    setShowFullDescription(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Planos"
          subtitle="Aqui você pode gerenciar os planos da sua empresa."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          {context && context.companyAdmin?.Company?.id === "0qjyNaZJ2TYndunFbXdc" ? (
            <div style={{ display: "flex", justifyContent: "start", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
              <Stack direction="row" spacing={1}>
                <Chip
                  label="Novo Plano"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "10px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                  }}
                  onClick={() => history.push('/novo-pacote')}
                />
              </Stack>
            </div>
          ) : null}
          <GridWrapper>
            {pacotes.map(b => (
              <Wrapper key={b.id} style={{
                padding: '20px',
                border: '1px solid #ddd',
                borderRadius: '15px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adiciona sombra para destaque
                position: 'relative', // Para posicionamento do botão de exclusão
                backgroundColor: '#fff', // Cor de fundo clara para contraste

              }}>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px'
                }}>
                  {context && context.companyAdmin?.Company?.id === "0qjyNaZJ2TYndunFbXdc" ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton onClick={() => DeletPacote(b.id)}>
                        <CancelIcon
                          style={{
                            color: "#ac3021",
                            width: "20px",
                            height: "20px"
                          }}
                        />
                      </IconButton>
                      {/* Adicionando margem para espaçamento */}
                      <IconButton onClick={() => history.push(`/editar-pacote/${b.id}`)} style={{ marginLeft: '1px' }}>
                        <EditIcon
                          style={{
                            color: theme.colors.primary,
                            width: "20px",
                            height: "20px"
                          }}
                        />
                      </IconButton>
                    </div>
                  ) : null}
                </div>
                <h1 style={{
                  fontSize: '24px',
                  fontFamily: "inherit",
                  fontWeight: 700,
                  lineHeight: '24px',
                  color: theme.colors.primary,
                  textAlign: 'center',
                  marginBottom: '10px'
                }}>
                  {b.name}
                </h1>

                <Rating name="size-large" value={getStarRating(b.qtdusuarios)} readOnly size="small" style={{ marginBottom: '10px' }} />
                <div style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  borderRadius: '50%',
                  width: '100px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  position: 'absolute',
                  top: '-30px',
                  fontSize:"15px",
                  right: '-30px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
                }}>
                  {formatCurrency(b.valor)}
                </div>

                <ul style={{
                  listStyleType: 'none',
                  padding: 0,
                  textAlign: 'center',
                  width: '100%',
                  marginLeft: "25%",
                  marginTop: '30px'
                }}>
                  <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} />
                    Quantidade de Filiais : <strong style={{ marginLeft: "2%" }}>{b.qtdempresas}</strong>
                  </li>
                  <li style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon style={{ color: 'green', marginRight: '10px' }} />
                    Quantidade de Usuários: <strong style={{ marginLeft: "2%" }}>{b.qtdusuarios}</strong>
                  </li>
                </ul>
                <p style={{
                  marginTop: '20px',
                  fontFamily: "inherit",
                  fontSize: '14px',
                  color: '#757575',
                  textAlign: 'center'
                }}>
                  <strong> Descrição :</strong> {b.descricao}
                </p>
                <button
                  style={{
                    backgroundColor: "#26ad26",
                    borderRadius: '30px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    width: "100%",
                    border: "none",
                    fontSize: '14px',
                    color: "#fff",
                    fontWeight: 'bold',
                    fontFamily: "inherit",
                    marginTop: "20px",
                    textAlign: 'center'
                  }}
                  onClick={() => handleBuyPack(b.id)}
                >
                  Adquirir Plano
                </button>
              </Wrapper>
            ))}
          </GridWrapper>
        </WrapperBack>
      </GridWrapper>
    </SectionWrapper>
  );
}

export default Pacotes;