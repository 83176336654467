
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../common/components/hooks/Auth";
import PageTitle from "../../../../common/components/PageTitle";
import { useHistory, useParams } from "react-router-dom";
import { ButtonBuy, Categoryaa, Description, Details, GridWrapper, Images, Price, SectionTitle, SectionWrapper, Title, Wrapper, WrapperBack, WrapperImage, } from "./styles";
import api from "../../../../services/api";
import { Chip, IconButton, Stack } from "@mui/material";
import { ThemeContext } from "styled-components";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';


interface Evento {
  id: string;
  nome: string;
  descricao: string;
  companyid: string;
  horainicio: string;
  horafim: string;
  datainicio: string;
  datafim: string;
  foto: string;
  capacidademax: number;
  banner: string;
}


function Evento() {
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const history = useHistory();
  const [beneficios, setBeneficios] = useState<Evento[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useContext(ThemeContext);
  const [showAll, setShowAll] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getBeneficios = async () => {
    try {

      const { data } = await api.get(`/eventos/empresa/${companyId}`);

      setBeneficios(data.data);
    } catch (e) {
      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }

  const DeletPacote = async (id: string) => {
    try {
      const { data } = await api.delete(`/eventos/delete/${id}`,);
      setBeneficios(data.data.data);
    } catch (e) {
      getBeneficios();
      alert("Produto Deletado com sucesso!");
      history.push("/disponibilizar/produto");


    } finally {
      setLoading(false);
    }
  }

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };


  useEffect(() => {
    getBeneficios();
  }, [])

  useEffect(() => {
    console.log(beneficios)
  }, [beneficios])
  function formatTime(dateString) {
    const date = new Date(dateString);
    return date.toLocaleTimeString('pt-br', { hour: '2-digit', minute: '2-digit' });
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-br');  // Formato ISO 8601 (YYYY-MM-DD)
  }




  return (
    <SectionWrapper>
      <SectionTitle>
        <PageTitle
          title="Eventos"
          subtitle="Aqui você pode gerenciar os eventos da sua empresa."
        />
      </SectionTitle>
      <GridWrapper>
        <WrapperBack>
          <div style={{ display: "flex", justifyContent: "end", width: "92%", marginBottom: "1%", marginTop: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Chip label="Novo Evento" style={{
                backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast,
                fontSize: "10px",
                padding: "7px 7px",
                fontWeight: "bold",
                marginRight: "2%"

              }}
                onClick={() => history.push(`/novo/evento`)} />
            </Stack>
          </div>
          <GridWrapper>
            {beneficios.map(b => (
              <Wrapper key={b.id}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <IconButton onClick={() => DeletPacote(b.id)}>
                    <CancelIcon
                      style={{
                        color: "#ac3021",
                        width: "20px",
                        height: "20px"
                      }} />
                  </IconButton><div style={{ marginLeft: 'auto' }}>
                    <IconButton onClick={() => history.push(`/editar/evento/${b.id}`)}>
                      <EditIcon
                        style={{
                          color: theme.colors.primary,
                          width: "20px",
                          height: "20px"
                        }} />
                    </IconButton>
                  </div>
                </div>
                <WrapperImage>
                  <Images src={b.foto} alt={'imagem evento'} />
                </WrapperImage>
                <Title>{b.nome}</Title>
                <Categoryaa>{b.descricao}</Categoryaa>
                <Details>Capacidade: {b.capacidademax}</Details>
                <Description>{formatDate(b.datainicio)} ás {formatTime(b.horainicio)}</Description>
              </Wrapper>
            ))}
          </GridWrapper>
        </WrapperBack>

      </GridWrapper>
    </SectionWrapper >
  );
}

export default Evento;