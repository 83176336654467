import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../common/components/Panel";
import PageTitle from "../../../../common/components/PageTitle";
import { Wrapper, ButtonWrapper, Button, SectionWrapper, GridWrapper, WrapperBack } from "./styles";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import {
  Table,
  Theader,
  TH,
  Tr,
} from "../../../../common/components/Table/styles";
import Dropdown from "../../../../common/components/DropdownDefault/Dropdown";
import Item from "../../../../common/components/DropdownDefault/Items";
import Toggle from "../../../../common/components/DropdownDefault/Toggle";
import Icon from "../../../../common/components/Icon";
import List from "../../../../common/components/DropdownDefault/List";
import Pagination from "../../../../common/components/Pagination";
import { format as formatCnpj } from "cnpj";
import { OperationVariables, QueryResult, useQuery } from "@apollo/client";
import { COMPANIES_QUERY } from "./graphql";
import { companies, companiesVariables } from "./types/companies";
import Paginator from "../../../../common/components/Paginator";
// import * as yup from "yup";
import Helmet from "react-helmet";
import CompaniesModal from "./Modal";
import DeleteModal from "./DeleteModal";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import NoResult from "../../../../common/components/NoResult";
import { TableWrapper } from "../Colab/styles";
import api from "../../../../services/api";
import { Company } from "./interfaces";
import { MenuItem, TablePagination } from "@material-ui/core";
import { ThemeContext } from "styled-components";
import { Avatar, TextField } from "@mui/material";
import { Stack, Chip, TableSortLabel, TableContainer } from "@mui/material";
import { BeneficConfig } from "../Benefits/interfaces";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";

interface OptionVal {
  value: string;
  text: string;
}


interface PacksPremiumConfig {
  id: string,
  packpremiumId: string,
  companyid: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
  dependet: boolean;

  packspremium: PacotesPremium;
}

interface PacotesPremium {
  id: string,
  name: string;
  valor: number;
  descricao: string;
}


const pageSize = 10;
const Companies: React.FC = () => {
  const context = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const companyId = context.companySelect.selectedValue;
  const [options, setOptions] = useState<OptionVal[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [parentId, setParentId] = useState<string | null>(null);
  const [convites, setConvites] = useState(0);
  const [premiumAtivos, setPremiumAtivos] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [beneficios, setBeneficios] = useState<{ companyId: string; beneficios: BeneficConfig[] }[]>([]);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const getCompanies = async () => {
    try {
      const params: any = { page: page + 1 };
      if (companyId) {
        params.companyId = companyId;
      }

      const { data } = await api.get("/company", { params });

      setCompanies(data.data);
      setTotal(data.total);
    } catch (e) {
      console.log(e);
    }
  };

  const getPacksPremiums = async () => {
    try {
      const params = { companyId };

      const { data } = await api.get("/pack/premium/active", { params });

      const pacotesAtivos = data.data.filter((packsPremium: PacksPremiumConfig) => packsPremium.companyid === companyId);
      console.log("teste1", pacotesAtivos)
      if (pacotesAtivos.length > 0) {
        setPremiumAtivos(true)
      }
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    }
  };

  const getConvitesRestantes = async () => {
    try {
      const { data } = await api.get(`/qtd-convite-empresas/${companyId}`);
      console.log("convites " + data.data)
      setConvites(data.data);
      setTotal(data.total);
    } catch (e) {
      console.log(e);
    }
  };

  const refreshTable = () => {
    setPage(0);
    setCompanies([]);
    getCompanies();
  };

  const isDisabled = (company: Company): boolean => {
    return company._count.Worker > 0 || company._count.WorkerInvite > 0;
  };

  const getCompanyDropdown = async () => {
    try {
      const { data } = await api.get(`/company/${companyId}/dropdown`);
      setOptions(data);
    } catch (e) {
      console.error(e);
    }
  };

  const transferCompany = async (currentCompanyId: string, targetCompanyId: string) => {
    try {
      await api.put(`/company/${currentCompanyId}/transfer`, {
        companyId: targetCompanyId,
      });
      alert("Empresa transferida com sucesso!");
      getCompanies();
    } catch (e) {
      console.error(e);
    }
  };
  const getBeneficios = async (companyId: string) => {
    try {
      const params = { companyId };
      const { data } = await api.get("/benefit-config", { params });
      return data.data; // Return the benefits data for the specific company
    } catch (e) {
      console.log(e);
      return []; // Return an empty array in case of an error
    }
  };

  useEffect(() => {
    const fetchBenefitsForCompanies = async () => {
      const benefitsData = await Promise.all(
        companies.map(async (company) => {
          const beneficios = await getBeneficios(company.id); // Fetch benefits for each company
          return { companyId: company.id, beneficios };
        })
      );
      setBeneficios(benefitsData); // Store the benefits data for each company
    };

    if (companies.length > 0) {
      fetchBenefitsForCompanies();
    }
  }, [companies]);

  useEffect(() => {
    getCompanies();
    getPacksPremiums();
    getConvitesRestantes();
  }, [page, companyId]);



  useEffect(() => {
    getCompanyDropdown();
  }, [companyId]);

  const mobile = useMediaQuery('(max-width: 700px)');
  const tablet = useMediaQuery('(max-width: 900px)')
  const laptop = useMediaQuery('(max-width: 1025px)')

  return (
    <>
      <div style={{ fontFamily: 'inherit' }}>


        <CompaniesModal
          id={updatingId}
          isShown={!!updatingId || isCreating}
          refresh={refreshTable}
          hide={() => {
            setUpdatingId(null);
            setIsCreating(false);
          }}
        />

        <DeleteModal
          id={deletingId}
          isShown={!!deletingId}
          refresh={refreshTable}
          hide={() => {
            setDeletingId(null);
          }}
        />

        <SectionWrapper>
          <GridWrapper>
            <WrapperBack>
              {tablet ? (
                <div style={{ display: "flex", flexDirection: "column", paddingLeft: "3%", paddingTop: "3%", marginRight: "2%", width: "100%" }}>
                  <PageTitle
                    title="Empresas"
                    subtitle="Aqui você pode cadastrar, listar e editar as empresas que utilizam os benefícios PeopleClub."
                  />

                  <div>
                    <p>Convites restantes: {premiumAtivos ? "Ilimitado" : convites}</p>
                    <Chip
                      label="Nova Empresa"
                      style={{
                        backgroundColor: premiumAtivos || convites > 0 ? theme.colors.primary : 'rgba(0,0,0,0.3)',
                        color: premiumAtivos || convites > 0 ? theme.colors.primaryContrast : 'rgba(255,255,255,0.5)',
                        fontSize: "10px",
                        padding: "7px 7px",
                        fontWeight: "bold",
                        marginRight: "2%",
                        cursor: premiumAtivos || convites > 0 ? 'pointer' : 'not-allowed',
                        opacity: premiumAtivos || convites > 0 ? 1 : 0.5
                      }}
                      onClick={() => {
                        if (premiumAtivos || convites > 0) {
                          setIsCreating(true);
                        }
                      }}
                      disabled={!(premiumAtivos || convites > 0)}
                    />
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: 'space-between', paddingLeft: "3%", paddingTop: "3%", marginRight: "2%", width: "100%" }}>
                  <PageTitle
                    title="Empresas"
                    subtitle="Aqui você pode cadastrar, listar e editar as empresas que utilizam os benefícios PeopleClub."
                  />

                  <div style={{ display: "flex", justifyContent: "end", marginTop: "1%", marginBottom: "5%", marginRight: "2%" }}>

                    <div>
                      <p>Convites restantes: {premiumAtivos ? "Ilimitado" : convites}</p>
                      <Chip
                        label="Nova Empresa"
                        style={{
                          backgroundColor: premiumAtivos || convites > 0 ? theme.colors.primary : 'rgba(0,0,0,0.3)',
                          color: premiumAtivos || convites > 0 ? theme.colors.primaryContrast : 'rgba(255,255,255,0.5)',
                          fontSize: "10px",
                          padding: "7px 7px",
                          fontWeight: "bold",
                          marginRight: "2%",
                          cursor: premiumAtivos || convites > 0 ? 'pointer' : 'not-allowed',
                          opacity: premiumAtivos || convites > 0 ? 1 : 0.5
                        }}
                        onClick={() => {
                          if (premiumAtivos || convites > 0) {
                            setIsCreating(true);
                          }
                        }}
                        disabled={!(premiumAtivos || convites > 0)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <br />

              <TableContainer sx={{ position: "relative", border: "1px solid #0000001f", borderRadius: "10px" }}>
                <Table>
                  <thead style={{ borderBottom: "1px solid #0000001f" }}>
                    <tr>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Nome</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >CNPJ</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      > Beneficios</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      > Usuários</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Transferir</Theader>
                      <Theader style={{
                        color: theme.colors.title,
                      }}
                      >Ações</Theader>

                    </tr>
                  </thead>

                  <tbody>
                    {companies.map((row) => {
                      const companyBenefits = beneficios.find((benefit) => benefit.companyId === row.id)?.beneficios || [];
                      console.log("Benefícios da empresa:", row.name, companyBenefits); // Verifica se está retornando os benefícios corretos para cada empresa

                      return (
                        <Tr key={row.id}>
                          <TH>{row.name ? row.name : row.companyName || "-"}</TH>
                          <TH>{row.cnpj ? formatCnpj(row.cnpj) : "-"}</TH>
                          <TH>
                            <div style={{ display: "flex", gap: "10px" }}>
                              {companyBenefits
                                .filter((b) => b.Benefit.title === "Meu Clube" || b.Benefit.title === "Clube de Descontos")
                                .map((b) => (
                                  <Avatar
                                    key={b.Benefit.id}
                                    style={{ width: "50px", height: "50px", border: "1px solid #e0e0e0", cursor: "pointer" }}
                                    src={b.Benefit.logoUrl}
                                    onClick={() => history.push(`/beneficio`)}
                                  />
                                ))}
                            </div>
                          </TH>
                          <TH>
                            {row._count.Worker || "-"} cadastrados
                            <br />
                            {row._count.WorkerInvite || "-"} convidados
                          </TH>

                          <TH>
                            <Dropdown>
                              <Toggle>
                                <Icon name="more-vertical" />
                              </Toggle>
                              <List>
                                <Item onClick={() => setUpdatingId(row.id)}>
                                  <Button>
                                    Editar empresa
                                  </Button>
                                </Item>
                                <Item disabled={isDisabled(row)} onClick={() =>
                                  isDisabled(row)
                                    ? alert(
                                      "Essa empresa não pode ser excluída pois possui convites, colaboradores ou gestores cadastrados."
                                    )
                                    : setDeletingId(row.id)
                                }>
                                  <Button>
                                    Excluir empresa
                                  </Button>
                                </Item>
                              </List>
                            </Dropdown>
                          </TH>
                          <TH>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Chip
                                label="Transferir"
                                style={{ backgroundColor: "#3f61be", color: "#fff", fontSize: "10px", fontWeight: "bold", borderRadius: "15px" }}
                                onClick={() => transferCompany(row.id, row.parentCompanyId)}  // Utilize parentCompanyId corretamente
                              />
                              <TextField
                                select
                                style={{ width: "180px", marginLeft: "2%" }}
                                size="small"
                                value={row.parentCompanyId || ''}  // Use parentCompanyId para definir o valor
                                onChange={(e) => {
                                  const selectedCompanyId = e.target.value;
                                  setCompanies((prevCompanies) =>
                                    prevCompanies.map((comp) =>
                                      comp.id === row.id ? { ...comp, parentCompanyId: selectedCompanyId } : comp
                                    )
                                  );
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: 300, // Define o tamanho máximo da lista para 300px
                                      },
                                    },
                                  },
                                }}
                              >
                                {options.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          </TH>
                        </Tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div style={{ display: "flex", justifyContent: mobile ? "flex-start" : "flex-end"}}>
                  <TablePagination
                    count={total}
                    page={page}
                    rowsPerPage={10}
                    rowsPerPageOptions={[]}
                    onChangePage={handleChangePage}
                    component="div"
                  />
                </div>
              </TableContainer>
            </WrapperBack>
          </GridWrapper>
        </SectionWrapper>
      </div>
    </>
  );
};

export default Companies;
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}

