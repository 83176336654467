import styled from "styled-components";
import { ifProp, prop } from "styled-tools";

export const TH = styled.td<{ textAlign?: string; ellipsis?: boolean }>`
  text-align: ${prop("textAlign", "left")};
  ${ifProp("ellipsis", "text-overflow: ellipsis;")}
  font-weight: 300;
  font-Family: 'inherit';
  font-size: 14px;
  line-height: 21px;
  color: #1D1D1D;
  width: 1050px;
height: 90px;
top: 219px;
left: 350px;
padding: 12px 6px 12px 17px;
gap: 0px;
border-bottom: 1px solid #0000001f;
opacity: 0px;

  gap: 10px;
  opacity: 0px;
  white-space: nowrap;


  &:hover {
    background-color: #f3edff;
  }
`;

export const Theader = styled.td`
  text-align: left;
  font-weight: 600;
  font-Family: 'Poppins';
  font-size: 16px;
  line-height: 21px;
  width: Fixed (1,050px)px;
height: Hug (69px)px;
padding: 12px 40px 12px 18px;
gap: 10px;
opacity: 0px;

  
`;

export const Table = styled.table`
max-height: 100vh;
background-color:"#fff";
top: 100px;
left: 290px;
gap: 0px;
border-radius: 0px 0px 0px 50px;
opacity: 0px;


 
`;

export const Tr = styled.tr`
  &:hover {
    background-color: #f3edff;
  }
`;

export const WrapperTable = styled.div`
  border-radius: 30px;
  padding: 100px;
  border: 1px solid black;
  margin-top: 28px;
`;
