import React, { useContext, useEffect, useState } from "react";
import Icon from "../Icon";
import { DivHeader, UserDiv, Button, SelectWrapper, NameWrapper, BoxMail } from "../Header/styles";
import Dropdown from "../DropdownDefault/Dropdown";
import Toggle from "../DropdownDefault/Toggle";
import { Item } from "../DropdownDefault/Items/styles";
import List from "../DropdownDefault/List";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../hooks/Auth";
import { ThemeContext } from "styled-components";
import { Select, MenuItem } from "@material-ui/core";
import Typography from "../Typography";
import { Box, IconButton } from "@mui/material";
import WalletIcon from '@mui/icons-material/Wallet';
import MailIcon from '@mui/icons-material/Mail';
import api from "../../../services/api";
import useMediaQuery from "@mui/material/useMediaQuery";

interface Pacotes {
  id: string,
  name: string;
  qtdempresas: number;
  qtdusuarios: number;
  valor: number;
  descricao: string;
}
interface PacksCompanyConfig {
  id: string,
  packId: string,
  companyid: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
  packs: Pacotes;
}


interface PacotesPremium {
  id: string,
  name: string;
  valor: number;
  descricao: string;
}

interface PacksPremiumConfig {
  id: string,
  packpremiumId: string,
  companyid: string,
  active: boolean,
  visibleToworkers: boolean,
  visibleTocompanyadmins: boolean,
  createdAt: string,
  updatedAt: string,
  dependet: boolean;

  packspremium: PacotesPremium;
}

interface Invite {
  id: string;
  workerEmail: string;
  externalId: string | null;
  companyId: string;
  createdAt: Date;
  acceptedAt: Date | null;
  emailSentAt: Date | null;
  sequential: number;
}


const HeaderDefault: React.FC = () => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const { selectedCompany, ...context } = useContext(AuthContext);
  const companyId = context.companySelect.selectedValue;
  const [packs, setPacks] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const label = { inputProps: { 'aria-label': 'Size switch demo' } };
  const [error, setError] = useState<string | null>(null);
  const [dependet, setDependet] = useState(false);
  const [totalUsuarios, setTotalUsuarios] = useState<number>(0);
  const [premiumAtivos, setPremiumAtivos] = useState<boolean>(false);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [sobrando, setSobrando] = useState<number>(0);

  const getPacksPremiums = async () => {
    try {
      const params = { companyId };

      const { data } = await api.get("/pack/premium/active", { params });

      // Filtrar pacotes ativos que pertencem ao companyId selecionado
      const pacotesAtivos = data.data.filter((packsPremium: PacksPremiumConfig) => packsPremium.companyid === companyId);
      console.log("teste1", pacotesAtivos)
      if (pacotesAtivos.length > 0) {
        setPremiumAtivos(true)
      }
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const getPacks = async () => {
    try {
      const params = { companyId };


      const { data } = await api.get("/pack-active", { params });

      // Somar a quantidade de usuários apenas dos pacotes que têm o mesmo companyId
      const total = data.data.reduce((acc: number, pack: PacksCompanyConfig) => {
        if (pack.companyid === companyId) { // Verifica se o companyId do pacote corresponde ao selecionado
          return acc + (pack.packs.qtdusuarios || 0);
        }
        return acc;
      }, 0);

      setTotalUsuarios(total);
    } catch (e) {
      console.log(e);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  const updatePacks = async () => {

    const { data } = await api.get(`/invites/sobrando/${companyId}`);
    setSobrando(data);
  };

  useEffect(() => {
    getPacks();
    getPacksPremiums();
    updatePacks();
  }, []);

  const mobile = useMediaQuery('(max-width: 700px')

  return (
    <DivHeader>
      <NameWrapper key="name-wrapper">
        {context.companyAdmin ? <>
          <h1
            style={{
              fontFamily: 'inherit',
              fontSize: '16px',
              marginTop: "2%",
              fontWeight: 500,
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >Olá,&nbsp;</h1>

          <h1
            style={{
              fontFamily: 'inherit',
              fontSize: '16px',
              marginTop: "2%",
              fontWeight: 500,
              lineHeight: '24px',
              textAlign: 'center',
            }}
          >{context.companyAdmin.firstName}</h1>
        </> : null}
      </NameWrapper>
      {mobile ? (
        <>
        </>
      ) : (
        <BoxMail >
          <IconButton>
            <MailIcon style={{ color: theme.colors.primary }} />
          </IconButton>

          <p
            style={{
              color: "#000",
              fontFamily: "inherit",
              fontSize: "16px",
              fontWeight: 400,
              marginLeft: "10%",
              lineHeight: "24px",
              margin: 0,
            }}
          >
            {premiumAtivos ? "Ilimitado" : sobrando}
          </p>
        </BoxMail>
      )}

      {context.companySelect.options.length > 1 ? (
        <SelectWrapper key="company-selector">
          <Select
            onChange={(e) => { context.selectCompanyId(e.target.value as string) }}
            style={{ boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.12)', borderRadius: "30px" }}
            value={context.companySelect.selectedValue}
            fullWidth
          >
            {context.companySelect.options
              .filter(option => {
                if (option.text === 'Nova Coop') {
                  return option.value === '7da4f20a-f8bf-47c9-85fb-650898af0407';
                }
                return true;
              }).map((option) => (
                <MenuItem value={option.value} key={option.value}>{option.text}</MenuItem>
              ))}
          </Select>
        </SelectWrapper>
      ) : null}
      {mobile ? (
        <Dropdown key="user-dropdown">
          <Toggle>
            <UserDiv>
              <Icon name="user" color={theme.colors.primary} />
            </UserDiv>
          </Toggle>
          <List>
            <Item style={{display:"flex", alignContent:"center", marginLeft:"5px", marginBottom:"3px"}}>
                <IconButton>
                  <MailIcon style={{ color: theme.colors.primary, marginBottom:"5px" }} />
                </IconButton>

                <p
                  style={{
                    color: "#000",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    fontWeight: 400,
                    marginLeft: "3%",
                    lineHeight: "24px",
                    margin: 0,
                  }}
                >
                  {premiumAtivos ? "Ilimitado" : sobrando}
                </p>
            </Item>
            <Link to="/perfil">
              <Item>
                <Button>Perfil</Button>
              </Item>
            </Link>
            <Item>
              <Link to="/custom-label">
                <Button>Editar Custom Label</Button>
              </Link>
            </Item>
            <a
              onClick={() => history.push(`/termos`)}
              target="_blank"
              rel="noreferrer"
            >
              <Item>
                <Button>Termos de uso</Button>
              </Item>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5511935854381&text=Ol%C3%A1,%20estou%20acessando%20este%20chamado%20atrav%C3%A9s%20do%20webapp%20de%20beneficios,%20preciso%20da%20seguinte%20ajuda:%0A%0A-%20..."
              target="_blank"
              rel="noreferrer"
            >
              <Item>
                <Button>Suporte</Button>
              </Item>
            </a>
            <Item onClick={() => context.unauthenticate()}>
              <Button>Sair</Button>
            </Item>
          </List>
        </Dropdown>
      ) : (
        <Dropdown key="user-dropdown">
          <Toggle>
            <UserDiv>
              <Icon name="user" color={theme.colors.primary} />
            </UserDiv>
          </Toggle>
          <List>
            <Link to="/perfil">
              <Item>
                <Button>Perfil</Button>
              </Item>
            </Link>
            <Item>
              <Link to="/custom-label">
                <Button>Editar Custom Label</Button>
              </Link>
            </Item>
            <a
              onClick={() => history.push(`/termos`)}
              target="_blank"
              rel="noreferrer"
            >
              <Item>
                <Button>Termos de uso</Button>
              </Item>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5511935854381&text=Ol%C3%A1,%20estou%20acessando%20este%20chamado%20atrav%C3%A9s%20do%20webapp%20de%20beneficios,%20preciso%20da%20seguinte%20ajuda:%0A%0A-%20..."
              target="_blank"
              rel="noreferrer"
            >
              <Item>
                <Button>Suporte</Button>
              </Item>
            </a>
            <Item onClick={() => context.unauthenticate()}>
              <Button>Sair</Button>
            </Item>
          </List>
        </Dropdown>
      )}

    </DivHeader>
  );
};

export default HeaderDefault;
