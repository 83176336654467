import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Col, Dropdown, Row, Spinner } from "react-bootstrap";
import AuthContext from "../../../../common/components/hooks/Auth";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import { Action, ActionsWrapper, BackButton, CheckoutCard, CheckoutCards, ExpandButton, ExpandButtonContainer, GridWrapper, Header, HtmlDescriptionCard, HtmlDescriptionContainer, HtmlDescriptionContent, Images, Label, LabelIconWrapper, Logo, PageTitleContainer, SectionWrapper, Wrapper, WrapperBanner } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import Icon from "../../../../common/components/Icon";
import { BenefitContext } from "../../../../common/components/hooks/Benefit";
import { setBenefitVisibilityToWorkers, setBenefitVisibilityToWorkersVariables } from "../Benefits/types/setBenefitVisibilityToWorkers";
import { MUTATION_SET_BENEFIT_VISIBILITY } from "../Benefits/graphql";
import { Beneficios } from "../Benefits/interfaces";
import api from "../../../../services/api";
import { Avatar, Paper, useMediaQuery } from "@material-ui/core";
import { BeneficiosDetails } from "./interfaces";
import { ThemeContext } from "styled-components";
import CarouselComponent from "../../../../components/Carousel";

const BenefitDetailsPage: React.FC = () => {
  const context = useContext(AuthContext);
  const [beneficios, setBeneficios] = useState<BeneficiosDetails | null>(null);
  const history = useHistory();
  const isMobileScreen = useMediaQuery("only screen and (max-width: 768px)");
  const benefitContext = useContext(BenefitContext);
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedDescription, setExpandedDescription] = useState(false);
  const [imagesUrls, setImagesUrls] = useState([]);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { benefitCode } = useParams<{ benefitCode: string }>()


  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getBenefitDetails = async () => {
    try {
      const { data } = await api.get(`/benefit/${benefitCode}`,);

      setBeneficios(data.data);
    } catch (e) {

      console.log(e)
      setError("Erro ao obter os benefícios da empresa.");

    } finally {
      setLoading(false);
    }
  }
  const DeleteBenefit = async (id: string) => {
    try {
      const { data } = await api.delete(`/delete/benefit/${id}`,);
      setBeneficios(data.data.data);
    } catch (e) {
      getBenefitDetails();
      alert("Beneficio Deletado com sucesso!");
      history.push("/beneficios");


    } finally {
      setLoading(false);
    }
  }
  const getBenefitBanners = async () => {
    try {
      const { data } = await api.get(`/benefit/banner/${beneficios?.id}`);
      const urls = data.data.BenefitCarouselImage.map(image => ({
        id: image.id,
        imageUrl: image.imageUrl
      }));
      setImagesUrls(urls);
    } catch (error) {
      console.error("Erro ao obter benefícios:", error);
      setError("Erro ao obter os benefícios da empresa.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (beneficios?.id) {
      getBenefitBanners();
    }
  }, [beneficios?.id]);

  const moneyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [setBenefitVisibilityToWorkers, setBenefitVisibilityToWorkersMutation] = useMutation<
    setBenefitVisibilityToWorkers,
    setBenefitVisibilityToWorkersVariables
  >(
    MUTATION_SET_BENEFIT_VISIBILITY
  );

  const companyHasBenefit = context.selectedCompany && !!context.selectedCompany.BenefitCompanyConfig.find(benefitConfig => beneficios?.id === benefitConfig.Benefit.id);
  const isBenefitVisibleToWorkers = context.selectedCompany && context.selectedCompany.BenefitCompanyConfig && !!context.selectedCompany.BenefitCompanyConfig.find(benefitConfig => beneficios?.id === benefitConfig.Benefit.id)?.visibleToWorkers;
  const existsInDraft = !!benefitContext.draftCompanyBenefitRequest?.benefits.find(b => b.id === beneficios?.id);
  useEffect(() => {
    getBenefitDetails();
  }, [benefitCode])


  return (
    <>
 <SectionWrapper>
 <GridWrapper>
 <Wrapper>
 <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <BackButton onClick={() => history.goBack()}>
          <Icon name="arrow-left" />
        </BackButton>
          <PageTitle
          title=" Detalhe do benefício"
          subtitle="Aqui você pode ver os detalhes do benefício."
        />
        <Dropdown style={{ marginLeft: 'auto', }}>
          <Dropdown.Toggle style={{ backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast, borderWidth: 1, borderRadius: 10 }} id="dropdown-basic">
            {setBenefitVisibilityToWorkersMutation.loading && (
              <Spinner style={{ backgroundColor: theme.colors.primary, color: theme.colors.primaryContrast, marginRight: 5 }} animation="border" size="sm" />
            )}
            Opções do benefício
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {companyHasBenefit && <>
              <Dropdown.Item
                onClick={() => setBenefitVisibilityToWorkers({
                  variables: {
                    input: {
                      benefitId: beneficios?.id,
                      companyId: context.companySelect.selectedValue,
                      visible: !isBenefitVisibleToWorkers
                    }
                  }
                })}
                disabled={setBenefitVisibilityToWorkersMutation.loading}
              >
                {setBenefitVisibilityToWorkersMutation.loading && (
                  <Spinner style={{ color: 'gray', marginRight: 3 }} animation="border" size="sm" />
                )}
                {isBenefitVisibleToWorkers ? 'Desativar' : 'Ativar'} Benefício para  Usuários
              </Dropdown.Item>
              <Dropdown.Item onClick={() => window.open(`https://wa.me/5511935854381?text=${encodeURIComponent(`Olá. Gostaria de renegociar o benefício ${beneficios?.title}.`)}`, '_blank')}>Renegociar Benefício</Dropdown.Item>
            </>}
            <Dropdown.Item onClick={() => window.open(`https://wa.me/5511935854381?text=${encodeURIComponent(`Olá. Gostaria de falar sobre o benefício ${beneficios?.title}.`)}`, '_blank')}>Entrar em Contato</Dropdown.Item>
            {context && (context.companyAdmin.Company.id === "0qjyNaZJ2TYndunFbXdc" || (context.companyAdmin.Company.id === "3HA9sj5NXNQKLT5UiuJg" && beneficios?.title.includes("BWG"))) ? (
              <>
              <Dropdown.Item onClick={() => history.push(`/editar-beneficio/${beneficios?.id}`)}>Editar Benefício</Dropdown.Item>
            <Dropdown.Item onClick={() => history.push(`/editar-banner/${beneficios?.id}`)}>Editar Banner</Dropdown.Item>
              <Dropdown.Item onClick={() => DeleteBenefit(beneficios?.id)}>
                Deletar Benefício
              </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
   

      <Header>

        <Images
        
          src={beneficios?.logoUrl}
        />

        <div style={{ paddingTop: "15%", paddingRight: "2%", paddingLeft: "12%" }}>
          <h1
            style={{
              fontFamily: 'inherit',
              fontSize: '18px',
              marginTop: "2%",
              fontWeight: 500,
              color:"#000000",
              lineHeight: '24px',
              textAlign: 'center',
              whiteSpace:"nowrap"
            }}>

            {beneficios?.title}
          </h1>
          <h1
            style={{
              fontFamily: 'inherit',
              fontSize: '16px',
              marginTop: "2%",
              fontWeight: 400,
              lineHeight: '24px',
              color:"#A7A7A7",
              textAlign: 'center',
            }}>
            {beneficios?.subtitle}
          </h1>
        </div>
      </Header>
      <div style={{ marginTop:"1%", paddingRight: "2%", paddingLeft: "2%" }}>
      <h1
            style={{
              fontFamily: 'inherit',
              fontSize: '12px',
              marginTop: "2%",
              fontWeight: 500,
              lineHeight: '24px',
              color:"#626262",
              textAlign: 'center',
            }}>
            {beneficios?.shortDescription}
          </h1>

        {beneficios?.locationLabel && (
          <Label>
            <LabelIconWrapper>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.25 7C13.25 10.75 8 14.25 8 14.25C8 14.25 2.75 10.75 2.75 7C2.75 5.60761 3.30312 4.27226 4.28769 3.28769C5.27226 2.30312 6.60761 1.75 8 1.75C9.39239 1.75 10.7277 2.30312 11.7123 3.28769C12.6969 4.27226 13.25 5.60761 13.25 7V7Z" stroke="#009418" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 8.25C8.69036 8.25 9.25 7.69036 9.25 7C9.25 6.30964 8.69036 5.75 8 5.75C7.30964 5.75 6.75 6.30964 6.75 7C6.75 7.69036 7.30964 8.25 8 8.25Z" fill="#009418" stroke="#009418" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </LabelIconWrapper>
            {beneficios?.locationLabel}
          </Label>
        )}
        {!!beneficios?.checkLabel.length && (
          <div style={{ display: 'flex', gap: 5 }}>
            {beneficios?.checkLabel.map((checkLabel) => (
              <Label>
                <LabelIconWrapper>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_5016_1148)">
                      <path d="M4.66699 7.29102L6.41699 9.04102L9.33366 5.54102" stroke="#4398D1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.00033 12.8327C10.222 12.8327 12.8337 10.221 12.8337 6.99935C12.8337 3.77769 10.222 1.16602 7.00033 1.16602C3.77866 1.16602 1.16699 3.77769 1.16699 6.99935C1.16699 10.221 3.77866 12.8327 7.00033 12.8327Z" stroke="#4398D1" strokeWidth="1.5" />
                    </g>
                    <defs>
                      <clipPath id="clip0_5016_1148">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </LabelIconWrapper>
                {checkLabel}
              </Label>
            ))}
          </div>
        )}
      </div>

      {/* {!!beneficios?.actions.length && isBenefitVisibleToWorkers && (
            <ActionsWrapper>
              {beneficios?.actions.map((action) => (
                <Action disabled={!!benefitContext.runningActionId} onClick={() => benefitContext.run(action, beneficios)}>
                  <div style={{ position: 'relative' }}>
                    {benefitContext.runningActionId === action.id && (
                      <Spinner style={{ color: context.selectedCompany?.CompanyTheme?.primaryContrastColorHex, marginLeft: -27, marginTop: 4, position: 'absolute' }} animation="border" size="sm" />
                    )}
                    {action.title}
                  </div>
                </Action>
              ))}
            </ActionsWrapper>
          )}   */}
      <div style={{ display: "flex", justifyContent: "end", paddingTop: "2%", paddingRight: "2%", paddingLeft: "2%", paddingBottom: "2%" }}>
       
        {!!beneficios?.checkoutCards && beneficios.checkoutCards.length > 0 && (
          <CheckoutCards>
            {beneficios.checkoutCards.map((card, index) => (
              <CheckoutCard key={card.id + '-' + index}>
                <Typography fontSize="18px" fontWeight="500">
                  {card.title}
                </Typography>
                {card.subtitle && (
                  <Typography fontSize="14px" fontWeight="400" marginBottom="15px">
                    {card.subtitle}
                  </Typography>
                )}
                {card.description && (
                  <div style={{ fontSize: 14, fontWeight: 400, marginBottom: 'auto', marginTop: 'auto' }} dangerouslySetInnerHTML={{ __html: card.description }} />
                )}
                {card.price && (
                  <Typography fontSize="22px" fontWeight="500" marginTop="auto">
                    {moneyFormatter.format(card.price)}
                  </Typography>
                )}
                {card.priceUnit && (
                  <Typography fontSize="16px" fontWeight="500" marginBottom="15px">
                    {card.priceUnit}
                  </Typography>
                )}
              </CheckoutCard>
            ))}
          </CheckoutCards>
        )}
      </div>
        <WrapperBanner>
          {loading ? (
            <p>Carregando...</p>
          ) : error ? (
            <p>{error}</p>
          ) : imagesUrls.length === 0 ? (
            <p>Nenhuma imagem disponível.</p>
          ) : (
            <CarouselComponent
              autoPlay={true}
              infiniteLoop={true}
              centerMode={true}
              width={isMobileScreen ? '100%' : '70%'}
            >
              {imagesUrls.map((image, index) => (
                <div key={image.id}>
                  <img
                    src={image.imageUrl}
                    alt={`Banner ${index}`}
                    style={{
                      height: isMobileScreen ? '100px' : '200px', // Ajuste a altura da imagem conforme necessário
                      width: '95%',
                      borderRadius: '5px',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              ))}
            </CarouselComponent>
          )}
        </WrapperBanner>
        </Wrapper>
        </GridWrapper>
        </SectionWrapper>

    </>

  );
};

export default BenefitDetailsPage;

