import styled from "styled-components";

export const Header = styled.nav``;

export const NameWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 350px;
  margin-right: auto;
  @media (min-width: 700px) and (max-width: 900px){
    margin-left: 175px;
  }
  @media (min-width: 900px) and (max-width: 1024px){
    margin-left: 200px;
  }
`;

export const SelectWrapper = styled.div`
  width: 250px;
  margin-top: 5px;
  margin-right: 20px;

  @media (min-width: 700px) and (max-width: 900px){
    margin-left: 20px;
  }
`;

export const DivHeader = styled.div`
  height: 92px;
  background-color:#fff;
  width: 100%;
  overflow-x: visible;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const BoxMail = styled.div`
   max-width: 100%;
   width: 120;
   height: 35px;
   margin-left: 2px;
   margin-right: 10px;
   background-color: #fff;
   border-radius: 30px;
   box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
   display: flex;
   align-items: center;
   padding: 0 10px;
`;


export const BellDiv = styled.div`
  padding-top: 10px;
  width: 60px;
`;
export const UserDiv = styled.div`
  margin-right: 36px;
  @media (min-width: 700px) and (max-width: 900px){
    margin-right: 5px;
  }
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
`;
