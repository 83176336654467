import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Tab from "../../../../common/components/TabDefault/Tab";
import Tabs from "../../../../common/components/TabDefault/Tabs";
import Typography from "../../../../common/components/Typography";
import {
  TabWrapper,
  AddLogo,
  ButtonWrapper,
  Savebutton,
  Testbutton,
  DivButtons,
  VerticalLine,
  WrapperUpdateLogo,
  SectionWrapper,
  SectionTitle,
  GridWrapper,
  WrapperBack,
} from "./styles";
import PrimaryButton from "../../../../common/components/PrimaryButton";
import { TwitterPicker } from "react-color";
import { Col, Container, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import AuthContext from "../../../../common/components/hooks/Auth";
import { Widget, WidgetLoader } from "react-cloudinary-upload-widget";
import { ThemeContext } from "styled-components";
import { env } from "process";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import { SuccessModal } from "./SuccessModal";
import Icon from "../../../../common/components/Icon";
import api from "../../../../services/api";
import Input from "../../../../common/components/Input";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import AvatarUpload from "./ImgUpload";
const CustomLabel: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedCompany, ...authContext } = useContext(AuthContext);
  const themeContext = useContext(ThemeContext);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [newUrl, setNewUrl] = useState("")
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [loginLogo, setLoginLogo] = useState<File>(null);
  const [LoginLogoUrl, setLoginLogoUrl] = useState('');
  const [texts, setTexts] = useState([
    { textoGrande: "", textoPequeno: "" },
    { textoGrande: "", textoPequeno: "" },
    { textoGrande: "", textoPequeno: "" },
    { textoGrande: "", textoPequeno: "" },
  ]);

  const handleTextChange = (index, field, event) => {
    const newTexts = [...texts];
    newTexts[index][field] = event.target.value;
    setTexts(newTexts);
  };

  const postImgLogin = async () => {

    try {
      const formData = new FormData();

      if (loginLogo) {
        formData.append("file", loginLogo);
      }

      await api.post(`/company/loginImg/${selectedCompany.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },

      });
      alert("Beneficio Atualizado com sucesso!");

    } catch (e) {
      console.log(e);
    }
  }

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setNewUrl(url);

    const urlPattern = /\.(com|com\.br)$/i;
    setIsValidUrl(urlPattern.test(url));
  };

  const schema = yup.object().shape({
    logoUrl: yup.string().url(),
  });

  console.log()

  const [previewLogoUrl, setPreviewLogoUrl] = useState<string | null>();

  useEffect(() => {
    reset({
      logoUrl: selectedCompany.logoUrl || themeContext.colors.logoUrl,
      primaryColorHex: themeContext.colors.primary,
      primaryContrastColorHex: themeContext.colors.primaryContrast,
      secondaryColorHex: themeContext.colors.secondary,
      secondaryContrastColorHex: themeContext.colors.secondaryContrast,
      titleColorHex: themeContext.colors.title,
      textColorHex: themeContext.colors.text,
    });
    // if (
    //   !companyTheme.data ||
    //   companyTheme.data.company.__typename !== "Company" ||
    //   !companyTheme.data.company.theme
    // ) {
    //   reset({
    //     logoUrl: defaultTheme.colors.logoUrl,
    //     primaryColorHex: defaultTheme.colors.primary,
    //     primaryContrastColorHex: defaultTheme.colors.primaryContrast,
    //     secondaryColorHex: defaultTheme.colors.secondary,
    //     secondaryContrastColorHex: defaultTheme.colors.secondaryContrast,
    //     titleColorHex: defaultTheme.colors.title,
    //     textColorHex: defaultTheme.colors.text,
    //   });
    //   return;
    // }

    // reset({
    //   logoUrl: companyTheme.data.company.theme.logoUrl,
    //   primaryColorHex: companyTheme.data.company.theme.primaryColorHex,
    //   primaryContrastColorHex: companyTheme.data.company.theme.primaryContrastColorHex,
    //   secondaryColorHex: companyTheme.data.company.theme.secondaryColorHex,
    //   secondaryContrastColorHex: companyTheme.data.company.theme.secondaryContrastColorHex,
    //   titleColorHex: companyTheme.data.company.theme.titleColorHex,
    //   textColorHex: companyTheme.data.company.theme.textColorHex,
    // });
  }, [themeContext.colors]);

  // const [updateTheme, mutationUpdateTheme] = useMutation<
  //   setCompanyTheme,
  //   setCompanyThemeVariables
  // >(MUTATION_SET_THEME_COMPANY);

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const formState = watch();

  const onSubmitUpdate = async (data: any) => {
    if (isLoading || !authContext.companySelect.selectedValue) {
      return;
    }

    setIsLoading(true);

    try {
      await api.put(`/company/${authContext.companySelect.selectedValue}/theme`, {
        primaryColorHex: data.primaryColorHex,
        logoUrl: data.logoUrl,
        primaryContrastColorHex: data.primaryContrastColorHex,
        secondaryColorHex: data.secondaryColorHex,
        secondaryContrastColorHex: data.secondaryContrastColorHex,
        titleColorHex: data.titleColorHex,
        textColorHex: data.textColorHex,
      });

      alert("Tema autualizado com sucesso!");
      return;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

    // const result = await updateTheme({
    //   variables: {
    //     input: {
    //       companyId: authContext.companySelect.selectedValue,
    //       logoUrl: data.logoUrl,
    //       primaryColorHex: data.primaryColorHex,
    //       primaryContrastColorHex: data.primaryContrastColorHex,
    //       secondaryColorHex: data.secondaryColorHex,
    //       secondaryContrastColorHex: data.secondaryContrastColorHex,
    //       titleColorHex: data.titleColorHex,
    //       textColorHex: data.textColorHex,
    //     },
    //   },
    // });
  };
  const submitDomain = async (data: any) => {
    if (!newUrl || !isValidUrl) {
      alert("Por favor, insira uma URL válida.");
      return;
    }

    setIsLoading(true);

    try {
      await api.put(`/company/${selectedCompany.id}/domain/${newUrl}`);
      alert("Solicitação para adicionar URL criado!");
      return;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

  }
  const getLoginImg = async () => {
    try {
      const { data } = await api.get(`/company/${selectedCompany.id}`,);
      console.log(data.data)
      setLoginLogoUrl(data.data.loginBackgroud ?? "");
      setNewUrl(data.data.domain)

    } catch (e) {

      console.log(e)
      setError("Erro ao obter imagem.");

    }
  }
  const handleSubmitTexts = async () => {
    try {
      const response = await api.put(`/company/${selectedCompany.id}/textos`, {
        texts
      });
      console.log("Textos atualizados com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao atualizar os textos de login:", error);
    }
  };
  useEffect(() => {
    if (selectedCompany.id) {
      getLoginImg()
    }
  }, [selectedCompany.id])

  const disabled =
    isLoading

  const currentLogoUrl = themeContext.colors.logoUrl;

  const successModal = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "17px" }}>
        <Icon name="success" height="74" width="74" />
      </div>
      <div style={{ marginBottom: "27px" }}>
        <Typography fontSize="22px" fontWeight="500">
          Custom Label salvo com sucesso
        </Typography>
      </div>
      <div>
        <Testbutton onClick={() => window.location.reload()}>Atualizar</Testbutton>
      </div>
    </div>
  );

  const widgetButtonText = !currentLogoUrl ? 'Escolher imagem' : (previewLogoUrl ? 'Escolher outra imagem' : 'Trocar imagem');

  const widget = (
    <Widget
      // and ID's as an object. More information on their use can be found at
      // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
      resourceType={"image"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
      cloudName={"peopleclub"} // your cloudinary account cloud name.
      // Located on https://cloudinary.com/console/
      uploadPreset={"companyLogoCustomLabel"} // check that an upload preset exists and check mode is signed or unisgned
      buttonText={widgetButtonText} // default 'Upload Files'
      style={{
        color: themeContext.colors.primaryContrast,
        border: "none",
        backgroundColor: themeContext.colors.primary,
        borderRadius: "15px",
        height: "30px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "20px",
      }}
      onSuccess={(data: any) => {
        console.log(data.info)
        setValue("logoUrl", data.info.secure_url);
        setPreviewLogoUrl(data.info.secure_url);
      }} // add success callback -> returns result
      onFailure={() => alert("Erro ao enviar a imagem. Tente novamente!")} // add failure callback -> returns 'response.error' + 'response.result'
      logging={env.NODE_ENV === "development"} // logs will be provided for success and failure messages,
      // eager={"c_pad,h_90,w_310"}
      // set to false for production -> default = true
      use_filename={false} // tell Cloudinary to use the original name of the uploaded
    // file as its public ID -> default = true,
    />
  );

  const bold = (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography
        fontSize="18px"
        fontWeight="600"
        fontColor="red"
        textAlign="center"
      >
        Atualizar Logo
      </Typography>
    </div>
  );

  return (
    <Col>
      <WidgetLoader />
      <SuccessModal
        isShown={openSuccess}
        hide={() => {
          setOpenSuccess(false);
          window.location.reload();
        }}
      >
        {successModal}
      </SuccessModal>
      <Row lg={12}>
      </Row>
      < SectionWrapper>
        <SectionTitle>

          <PageTitle
            title="Editar Custom Label"
            subtitle="Aqui você pode adicionar seu logotipo e editar as combinações de cores da sua plataforma"
          />

        </SectionTitle>
        <GridWrapper>
          <WrapperBack>
            <>
              <Tabs>
                <Tab title="Logotipo">
                  <div
                    style={{
                      marginTop: "42px",
                      marginLeft: "24px",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography fontSize="16px" fontWeight="400">
                      Configure aqui o logotipo da sua empresa.
                    </Typography>
                  </div>

                  <Row>
                    {currentLogoUrl ? (
                      <Col lg={6} style={{ height: "150px", borderRight: '1px solid gray' }}>
                        <div>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <Typography
                              fontSize="15px"
                              fontWeight="500"
                              marginBottom="10px"
                              marginTop="20px"
                            >
                              Imagem atual
                            </Typography>
                          </div>
                          <AddLogo>
                            {currentLogoUrl ? (
                              <div
                                style={{
                                  marginLeft: "20%",
                                  marginRight: "20%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignSelf: "center",
                                  }}
                                >
                                  <img
                                    alt="Logo Thumbnail Url"
                                    src={selectedCompany.logoUrl || selectedCompany.parent?.logoUrl || themeContext.colors.logoUrl}
                                    style={{
                                      height: "45px",
                                      width: "155px",
                                      border: "1px solid #D7D7D7",
                                      backgroundImage: `url("data:image/svg+xml; utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><rect fill='%23ddd' width='5' height='5' x='0' y='0' /><rect fill='%23ddd' width='5' height='5' x='5' y='5' /><rect fill='%23fff' width='5' height='5' x='5' y='0' /><rect fill='%23fff' width='5' height='5' x='0' y='5' /></svg>")`,
                                      backgroundRepeat: "repeat",
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </AddLogo>
                        </div>
                      </Col>
                    ) : null}
                    <Col
                      lg={6}
                      style={{
                        minHeight: "150px",
                      }}
                    >
                      {previewLogoUrl ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              fontSize="15px"
                              fontWeight="500"
                              marginBottom="10px"
                              marginTop="20px"
                            >
                              Preview
                            </Typography>
                          </div>
                          <div
                            style={{
                              marginLeft: "20%",
                              marginRight: "20%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            >
                              <img
                                src={previewLogoUrl}
                                width="155px"
                                height="45"
                                style={{
                                  border: "1px solid #D7D7D7",
                                  backgroundImage: `url("data:image/svg+xml; utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><rect fill='%23ddd' width='5' height='5' x='0' y='0' /><rect fill='%23ddd' width='5' height='5' x='5' y='5' /><rect fill='%23fff' width='5' height='5' x='5' y='0' /><rect fill='%23fff' width='5' height='5' x='0' y='5' /></svg>")`,
                                  backgroundRepeat: "repeat",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        >
                          {widget}
                        </div>
                      )}
                    </Col>
                  </Row>

                  <WrapperUpdateLogo>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "15px",
                        marginBottom: "20px",
                      }}
                    >
                      <Controller
                        control={control}
                        name="logoUrl"
                        defaultValue=""
                        render={({ field }) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {previewLogoUrl ? widget : null}
                          </div>
                        )}
                      />
                    </div>
                    {previewLogoUrl ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "20px",
                          alignSelf: "center",
                        }}
                      >
                        <Typography
                          fontSize="18px"
                          fontWeight="400"
                          fontColor="red"
                          textAlign="center"
                          marginLeft="15px"
                        >
                          Verifique se o corte da imagem ficou satisfatório e
                          clique em <b>Atualizar Logo</b>
                        </Typography>
                      </div>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "10px",
                      }}
                    >
                      <PrimaryButton
                        onClick={handleSubmit(onSubmitUpdate)}
                        disabled={!previewLogoUrl || disabled}
                        paddingLeft="30px"
                        paddingRight="30px"
                      >
                        Atualizar logo
                      </PrimaryButton>
                    </div>
                  </WrapperUpdateLogo>
                </Tab>

                <Tab title="Cor de títulos e textos">
                  <Row>
                    <Col lg={6}>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="15px"
                        marginLeft="24px"
                        marginTop="44px"
                      >
                        Defina uma cor para os títulos
                      </Typography>
                      <Controller
                        control={control}
                        name="titleColorHex"
                        defaultValue=""
                        render={({ field }) => (
                          <div style={{ marginLeft: "24px" }}>
                            <TwitterPicker
                              colors={[
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#FFFFFF",
                                "#0693E3",
                                "#EB144C",
                                "#8E5AED",
                                "#000000",
                              ]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          </div>
                        )}
                      />
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="15px"
                        marginLeft="24px"
                        marginTop="44px"
                      >
                        Defina uma cor para os textos
                      </Typography>
                      <div style={{ marginLeft: "24px" }}>
                        <Controller
                          control={control}
                          name="textColorHex"
                          defaultValue=""
                          render={({ field }) => (
                            <TwitterPicker
                              colors={["#000000", "#5C5C5C", "#CACACA"]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          )}
                        />
                      </div>
                    </Col>

                    <Col md={12} lg={6}>
                      <Panel
                        borderRadius="10px"
                        marginLeft="24px"
                        marginTop="44px"
                        bgColor="#F7F7F7"
                        minHeight="300px"
                      >
                        <Typography
                          fontSize="28px"
                          fontWeight="500"
                          fontColor={formState.titleColorHex}
                        >
                          Exemplo de Título
                        </Typography>
                        <Typography
                          fontSize="22px"
                          fontWeight="400"
                          fontColor={formState.textColorHex}
                        >
                          Exemplo de Texto
                        </Typography>
                      </Panel>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      marginTop: "45px",
                      marginLeft: "15px",
                      marginBottom: "49px",
                    }}
                  ></hr>

                  <DivButtons>
                    <ButtonWrapper>
                      <Savebutton
                        disabled={disabled}
                        type="submit"
                        onClick={handleSubmit(onSubmitUpdate)}
                      >
                        Salvar
                      </Savebutton>
                    </ButtonWrapper>
                  </DivButtons>
                </Tab>

                <Tab title="Cor de botões">
                  <Row>
                    <Col md={12} lg={6}>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="15px"
                        marginLeft="24px"
                        marginTop="44px"
                      >
                        Defina uma cor para os botões primários
                      </Typography>
                      <div style={{ marginLeft: "24px", marginBottom: "44px" }}>
                        <Controller
                          control={control}
                          name="primaryColorHex"
                          defaultValue=""
                          render={({ field }) => (
                            <TwitterPicker
                              colors={[
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#FFFFFF",

                                "#0693E3",
                                "#EB144C",
                                "#F78DA7",
                                "#8E5AED",
                              ]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          )}
                        />
                      </div>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="14px"
                        marginLeft="24px"
                      >
                        Defina uma cor para a fonte dos botões primários
                      </Typography>
                      <div style={{ marginLeft: "24px", marginBottom: "44px" }}>
                        <Controller
                          control={control}
                          name="primaryContrastColorHex"
                          defaultValue=""
                          render={({ field }) => (
                            <TwitterPicker
                              colors={[
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#FFFFFF",

                                "#0693E3",
                                "#EB144C",
                                "#F78DA7",
                                "#8E5AED",
                              ]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <Panel
                        borderRadius="10px"
                        marginTop="44px"
                        bgColor="#F7F7F7"
                        minHeight="300px"
                      >
                        <Typography
                          fontSize="20px"
                          fontWeight="400"
                          marginBottom="10px"
                        >
                          Exemplo de botão primário:
                        </Typography>
                        <PrimaryButton
                          bgColor={formState.primaryColorHex}
                          width="50%"
                          marginBottom="20px"
                          color={formState.primaryContrastColorHex}
                        >
                          Botão
                        </PrimaryButton>
                      </Panel>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} lg={6}>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="14px"
                        marginLeft="24px"
                      >
                        Defina uma cor para os botões secundários
                      </Typography>
                      <div style={{ marginLeft: "24px", marginBottom: "44px" }}>
                        <Controller
                          control={control}
                          name="secondaryColorHex"
                          defaultValue=""
                          render={({ field }) => (
                            <TwitterPicker
                              colors={[
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#FFFFFF",
                                "#0693E3",
                                "#EB144C",
                                "#F78DA7",
                                "#8E5AED",
                              ]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          )}
                        />
                      </div>
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="14px"
                        marginLeft="24px"
                      >
                        Defina uma cor para a fonte dos botões secundários
                      </Typography>
                      <div style={{ marginLeft: "24px" }}>
                        <Controller
                          control={control}
                          name="secondaryContrastColorHex"
                          defaultValue=""
                          render={({ field }) => (
                            <TwitterPicker
                              colors={[
                                "#FF6900",
                                "#FCB900",
                                "#7BDCB5",
                                "#00D084",
                                "#8ED1FC",
                                "#FFFFFF",
                                "#0693E3",
                                "#EB144C",
                                "#F78DA7",
                                "#8E5AED",
                              ]}
                              color={field.value}
                              onChangeComplete={(color) =>
                                field.onChange(color?.hex || "")
                              }
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12} lg={6}>
                      <Panel
                        borderRadius="10px"
                        bgColor="#F7F7F7"
                        minHeight="300px"
                      >
                        <Typography
                          fontSize="20px"
                          fontWeight="400"
                          marginBottom="10px"
                        >
                          Exemplo de botão secundário:
                        </Typography>
                        <PrimaryButton
                          bgColor={formState.secondaryColorHex}
                          width="50%"
                          color={formState.secondaryContrastColorHex}
                        >
                          Botão
                        </PrimaryButton>
                      </Panel>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      marginTop: "50px",
                      marginLeft: "15px",
                      marginBottom: "31px",
                    }}
                  ></hr>

                  <DivButtons>
                    <ButtonWrapper>
                      <Savebutton
                        disabled={disabled}
                        type="submit"
                        onClick={handleSubmit(onSubmitUpdate)}
                      >
                        Salvar
                      </Savebutton>
                    </ButtonWrapper>
                  </DivButtons>
                </Tab>
                <Tab title="URL personalizada">
                  <div
                    style={{
                      marginTop: "42px",
                      marginLeft: "24px",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography fontSize="16px" fontWeight="400">
                      Configure uma url personalizada para sua empresa.
                    </Typography>
                  </div>
                  <Row style={{ marginLeft: "10%", marginRight: "15%" }}>

                    <TextField
                      fullWidth
                      label="URL"
                      id="fullWidth"
                      value={newUrl}
                      onChange={handleUrlChange}
                      error={!isValidUrl}
                      helperText={!isValidUrl ? 'URL inválida. Deve incluir .com ou .com.br' : ''}
                    />
                    <hr
                      style={{
                        marginTop: "50px",
                        marginLeft: "15px",
                        marginBottom: "31px",
                      }}
                    ></hr>
                    <DivButtons>
                      <ButtonWrapper>
                        <Savebutton
                          type="submit"
                          onClick={submitDomain}
                        >
                          Salvar
                        </Savebutton>
                      </ButtonWrapper>
                    </DivButtons>
                  </Row>
                </Tab>
                {newUrl ? (

                  <Tab title="Imagem de login">
                    <div
                      style={{
                        marginTop: "42px",
                        marginLeft: "24px",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography fontSize="16px" fontWeight="400">
                        Altere a foto da tela de login da sua empresa
                      </Typography>
                    </div>
                    <Row style={{ marginLeft: "10%", marginRight: "15%" }}>

                      <Grid item xs={12}>
                        <AvatarUpload
                          onChangeImage={setLoginLogo}
                          src={(LoginLogoUrl || "")}
                        />
                      </Grid>
                      <hr
                        style={{
                          marginTop: "50px",
                          marginLeft: "15px",
                          marginBottom: "31px",
                        }}
                      ></hr>
                      <DivButtons>
                        <ButtonWrapper>
                          <Savebutton
                            type="submit"
                            onClick={postImgLogin}
                          >
                            Salvar
                          </Savebutton>
                        </ButtonWrapper>
                      </DivButtons>
                    </Row>
                  </Tab>
                ) : (<></>)}
                {newUrl ? (
                  <Tab title="Textos de login">
                    <div
                      style={{
                        marginTop: "42px",
                        marginLeft: "24px",
                        marginBottom: "20px",
                      }}
                    >
                      <Typography fontSize="16px" fontWeight="400">
                        Altere os textos na tela de login da sua empresa
                      </Typography>
                    </div>
                    <Row style={{ marginLeft: "10%", marginRight: "15%" }}>
                      {texts.map((text, index) => (
                        <div key={index} style={{ marginBottom: "20px", width: "100%", border: "1px solid grey", padding:"10px", borderRadius: "10px" }}>
                          <TextField
                            fullWidth
                            label={`Texto Grande ${index + 1}`}
                            value={text.textoGrande}
                            onChange={(e) => handleTextChange(index, "textoGrande", e)}
                            style={{ marginBottom: "10px" }}
                          />
                          <TextField
                            fullWidth
                            label={`Texto Pequeno ${index + 1}`}
                            value={text.textoPequeno}
                            onChange={(e) => handleTextChange(index, "textoPequeno", e)}
                            />
                            <br></br>
                        </div>
                      ))}
                      <hr
                        style={{
                          marginTop: "50px",
                          marginLeft: "15px",
                          marginBottom: "31px",
                        }}
                      ></hr>
                      <DivButtons>
                        <ButtonWrapper>
                          <Savebutton
                            type="submit"
                            onClick={handleSubmitTexts}
                          >
                            Salvar
                          </Savebutton>
                        </ButtonWrapper>
                      </DivButtons>

                    </Row>
                  </Tab>
                ) : (<></>)}
              </Tabs>
            </>
          </WrapperBack>

        </GridWrapper>
      </SectionWrapper >
    </Col>
  );
};

export default CustomLabel;
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}

