/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionValueType {
  TOKEN_TO_GENERATE_URL = "TOKEN_TO_GENERATE_URL",
  URL_TO_OPEN = "URL_TO_OPEN",
}

export enum CompanyBenefitRequestStatus {
  ANALYSIS = "ANALYSIS",
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  IMPLEMENTATION = "IMPLEMENTATION",
}

export enum KnownActions {
  ACCESS = "ACCESS",
  CHECKOUT = "CHECKOUT",
  LEARN_MORE = "LEARN_MORE",
  SUPPORT = "SUPPORT",
  TERMS = "TERMS",
}

export enum ProductRequestType {
  PRODUCT_DISABLE = "PRODUCT_DISABLE",
  PRODUCT_ENABLE = "PRODUCT_ENABLE",
}

export enum UrlWebTarget {
  IFRAME = "IFRAME",
  NEW_TAB = "NEW_TAB",
}

export enum ValidationRule {
  BAD_FORMAT = "BAD_FORMAT",
  BAD_REFERENCE = "BAD_REFERENCE",
  CNPJ = "CNPJ",
  CPF = "CPF",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  EMAIL = "EMAIL",
  MAX_LENGTH = "MAX_LENGTH",
  MIN_LENGTH = "MIN_LENGTH",
  PHONE = "PHONE",
  REQUIRED = "REQUIRED",
  UNIQUE = "UNIQUE",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  URL = "URL",
}

export interface BenefitVisibilityToWorkersInput {
  companyId: string;
  benefitId: string;
  visible: boolean;
}

export interface CompanyBenefitRequestFilter {
  status?: CompanyBenefitRequestStatus | null;
  year: number;
}

export interface CompanyThemeInput {
  companyId: string;
  logoUrl: string;
  primaryColorHex: string;
  primaryContrastColorHex: string;
  secondaryColorHex: string;
  secondaryContrastColorHex: string;
  titleColorHex: string;
  textColorHex: string;
}

export interface CreateCompanyAdminInput {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  department?: string | null;
  role?: string | null;
}

export interface CreateCompanyInput {
  parentCompanyId: string;
  name: string;
  companyName: string;
  contactEmail: string;
  cnpj: string;
  logoUrl?: string | null;
}

export interface CreateWorkerInviteInput {
  workerEmail: string;
  companyId: string;
  workerDisplayName?: string | null;
  sendEmail?: boolean | null;
  resendIfPending?: boolean | null;
}

export interface CreateWorkerNotificationInput {
  companyId: string;
  title: string;
  subtitle: string;
  body: string;
}

export interface FinishCompanyBenefitRequestInput {
  companyBenefitRequestId: string;
  contactName: string;
  contactEmail: string;
  contactPhone?: string | null;
  message?: string | null;
}

export interface ProductRequestContactInput {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string | null;
  message?: string | null;
}

export interface PutBenefitIntoRequestDraftInput {
  benefitId: string;
  companyId: string;
}

export interface RemoveBenefitFromRequestDraftInput {
  benefitId: string;
  companyId: string;
}

export interface UpdateCompanyAdminInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  companyId?: string | null;
  department?: string | null;
  role?: string | null;
  domain?: string | null;
}

export interface UpdateCompanyInput {
  companyId: string;
  parentCompanyId?: string | null;
  name?: string | null;
  companyName?: string | null;
  contactEmail?: string | null;
  cnpj?: string | null;
  logoUrl?: string | null;
}

export interface UpdateWorkerNotificationInput {
  id: string;
  title: string;
  subtitle: string;
  body: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
